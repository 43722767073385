import React from "react";

import Layout from "../components/layout";
import { Link } from "gatsby";

const NotFoundPage = ({ location }) => (
  <Layout
    seo={{ title: "Page not found", href: location.pathname, lang: "pl" }}
  >
    <section className="container-fluid page_404">
      <h2>Ta strona nie istnieje</h2>
      <br />
      <div className="btn_wrapper">
        <Link to="/" className="btn_custom">
          Strona główna
        </Link>
      </div>
      <img className="svg" src={require("../assets/img/ico_404.svg")} alt="" />
    </section>
  </Layout>
);

export default NotFoundPage;
